<!-- components/InvisibleRecaptcha.vue -->
<template>
  <div></div>
</template>

<script setup>
import {onMounted, ref} from 'vue';

const props = defineProps({
  siteKey: {
    type: String,
    required: true
  },
  callback: {
    type: Function,
    required: true
  }
});

const recaptchaId = ref(null);

onMounted(() => {
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?render=${props.siteKey}`;
  script.async = true;
  script.defer = true;
  script.onload = () => {
    window.grecaptcha.ready(() => {
      recaptchaId.value = props.siteKey;
    });
  };
  document.head.appendChild(script);
});

const executeRecaptcha = () => {
  if (window.grecaptcha && recaptchaId.value !== null) {
    window.grecaptcha.execute(recaptchaId.value, {action: 'submit'}).then((token) => {
      props.callback(token);
    });
  }
};

defineExpose({executeRecaptcha});
</script>
